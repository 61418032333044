import React from "react";
import { presentationTrips } from "../components/Trips/trips-data";
import MoreInformation from "../components/Trips/MoreInformation";
import { useTranslation } from "react-i18next";

const tripInformation = presentationTrips.find((x) => x.id === "dolphins");

const Dolphins = () => {
  const { t } = useTranslation();
  return (
    <MoreInformation
      title="Dolphin watching"
      description="Embark on a unique experience and discover the thrill of seeing dolphins in their natural habitat. We leave from Puerto de Pollença a few miles out to sea early in the morning, when the sea is calm and the sun begins to rise. You will be able to enjoy the warm colors of sunrise, the sight of these animals swimming freely and breakfast on board. Book now and live an adventure that you will remember forever!"
      tripInformation={tripInformation}
      timeTable={t(tripInformation.moreInformation.timetable)}
    />
  );
};

export default Dolphins;
